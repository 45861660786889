import Vue from 'vue'
import App from './App.vue'
import './styles/index.css' //全局css
import 'animate.css'; //引入动画css库

import store from './store/index'; //vuex
import MessagePlugin from '@/utils/tips';//封装的提示
// import 'lib-flexible'
import VueLazyload from "vue-lazyload";  //图片懒加载

import router from './router/index'

import globalMixin from '@/mixin/beforeupdate'
Vue.mixin(globalMixin)


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(MessagePlugin);


// const errorimage = require('assets/img/common/error.gif')
Vue.use(VueLazyload, {
  preLoad: 1.3, //预加载的宽高比
 // loading: loadimage, //图片加载状态下显示的图片
  // error: errorimage, //图片加载失败时显示的图片
  attempt: 1, // 加载错误后最大尝试次数
})
Vue.use(VueLazyload);

// console.log(router)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')