<template>
  <div id="app">
           <!-- 全局禁止点击 -->

<div  element-loading-text="正在处理中，请稍后"  v-loading.fullscreen="$store.getters.isstorpclick"  element-loading-background="rgba(0, 0, 0, 0.1)">
    <router-view></router-view>
</div>
     
  </div>
</template>

<script>

// 模板已经适配, 1rem是37.5px  设计稿宽如果是375
// swiper 下载的是 5.4.5 版本的
// axios css动画库 jquery 都已下载


export default {
  name: 'App',
  components: {
    
  }
}


</script>

<style scoped lang="scss">
#app {
  height: 100%;
}

</style>