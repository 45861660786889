import request from '@/utils/axios'

export function priceTemplate(params) { //获取价格模板
  return request({
    url: '/qy/template',
    method: 'get',
    params
  })
}


export function getPriceUrl(params) { //获取支付的二维码
  return request({
    url: '/qy/pay_qrcode',
    method: 'get',
    params
  })
}

export function inquirePaySuces(params) { //查询是否支付成功
  return request({
    url: '/pay/orders',
    method: 'get',
    params
  })
}

export function bingApi(params) { //bing
  return request({
    url: '/qy/bing_back',
    method: 'get',
    params
  })
}

