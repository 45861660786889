<template>
  <el-dialog
    @close="dialogClose"
    :visible="isispopvip"
    :close-on-click-modal="false"
    width="58.5%"
  >
    <div class="vipbg">
      <div class="closevip" @click="closevipBtn"></div>

      <!-- 支付 -->
      <div class="pay">
        <div class="payActive">
          <div
            :class="['payItem', wxiszfb === 1 ? 'xzActive' : '']"
            @click="btnwxIszfb(1)"
          >
            <img src="@/assets/vip/wx.png" alt="" />
            <span>微信支付</span>
          </div>
          <div
            :class="['payItem', wxiszfb === 2 ? 'xzActive' : '']"
            @click="btnwxIszfb(2)"
          >
            <img src="@/assets/vip/zfb.png" alt="" />
            <span>支付宝支付</span>
          </div>
        </div>

        <div class="amount">应付：￥{{copewith}}</div>

        <div v-loading="ewmloading" ref="payewm" class="payewm" id="payewm">
          <img
            ref="wxpayImg"
            v-if="wxiszfb === 1"
            :src="wxzhifuerweima"
            alt=""
          />
          <iframe
            width="78%"
            height="81%"
            ref="zfbiframe"
            id="zfbiframe"
            :src="zfbfukuanma"
            frameborder="0"
            v-else
          ></iframe>
        </div>
      </div>

      <!-- 选择套餐 -->
      <div class="Setmeal">
        <div
          :class="['mealItem', xzMeal === index + 1 ? 'mealItemActive' : '']"
          v-for="(i, index) in templateList"
          :key="index"
          @click="mealItemBtn(i, index)"
        >
          <div class="title">
            {{ i.type_name }}
          </div>
          <div class="ling"  >
            <span>￥</span
            >
            <span 
           
            style="font-size: 30px; margin: 0 5px">{{
              i.spare_label
            }}</span>

            <span>/天</span>
          </div>
          <div class="xj">现价{{ i.price }}元</div>
          <div class="yj">
            <span style="font-size:10px">原价</span>{{ i.bottom_label }} <span style="font-size:10px">元</span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { priceTemplate, getPriceUrl, inquirePaySuces,bingApi } from "@/api/pay";
import QRCode from "qrcode"; //生成二维码的库
import { mapGetters } from "vuex";
import assist from '@/utils/commonality'
export default {
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      wxiszfb: 1, //1是微信 2是支付宝
      xzMeal: 3, //选择哪个套餐
      templateList: [],
      templateData: {
        app_id: assist.getAppId(),
        channel_id: assist.getChannelId(),
        packet: assist.getPacket(),
        other_id: assist.getOtherId(),
      },
      payEwm: "", //支付的二维码
      wxiszfbEwm: {
        wx: { url: "", out_trade_no: "" },
        ali: { url: "", out_trade_no: "" },
      },
      wxzhifuerweima: "", //微信支付的二维码
      zfbfukuanma: "", //支付宝支付的二维码
      ewmloading: false, //二维码加载状态
      copewith:'',//应付金额
      timeout: 2e3,//轮询时间
      interval:null,
      times:0,//轮询初始值
      maxTimes:300,//最大轮询次数
    };
  },
  computed: {
    ...mapGetters(["isispopvip", "isuid", "isuserInfo"]),
  },
  watch: {
    async isispopvip(newValue) {
      if (newValue) {
        await this.gettemplateList();
        await this.getewm(this.templateList[2].id);
 
       if (this.interval !== null) clearTimeout(this.interval);
         this.interval  =  setTimeout(() => {
               this.payisSuces();
        },this.timeout)

     
      }
      else {
        clearTimeout(this.interval);
        this.xzMeal = 3
      }
    },

  },
  created() {},
  async mounted() { 
  },
  methods: {
   async getbing(price, out_trade_no, callback) { //价格 订单号 回调方法  需要支付价格跟订单号
      
      console.log(assist.getPacket());
      console.log(price, out_trade_no);

   let res = '';
    if (assist.getPacket()=='bing') {
      console.log('进来的');
    
        window.uetq = window.uetq || [];
        window.uetq.push('event', 'purchase', {"revenue_value": price, "currency": "CNY"});
        let res = await bingApi({
            out_trade_no:out_trade_no
        })
        if (res.data.status === 1) {
         
        }
    }
       
        // 成功以后自定义逻辑
        //需要判断  
        /**
         * 判断当前的环境是不是必应  判断回传成功没
         */
        // callback && callback();
        return res;
    },
    async gettemplateList() {
      //三种价格的模板 请求获取模板
      let res = await priceTemplate(this.templateData);
      this.templateList = res.data.list;
      this.copewith = this.templateList[2].price
      this.templateList.splice(this.templateList.length - 1, 1);
    },
    async getewm(shopid) {
      this.ewmloading = true; //开启loading加载
      try {
       
        let res = await getPriceUrl({
          app_id: assist.getAppId(),
          channel_id: assist.getChannelId(),
          packet: assist.getPacket(),
          other_id:assist.getOtherId(),
          uid: this.isuid,
          custom: JSON.stringify({
                       shop_name: '图片视频压缩',
                       shop_id: shopid,
                }),
          shop_id: shopid,
          redirect_url: window.location.href,
        });
           
        this.wxiszfbEwm = res.data;
        
        // 生成二维码图片
        let url = await new Promise((resolve, reject) => {
          QRCode.toDataURL(res.data.wx.url, (err, url) => {
            if (err) {
              reject(err);
            } else {
              resolve(url);
            }
          });
        });
        // 设置图片的 src
        this.wxzhifuerweima = url;
        this.zfbfukuanma = res.data.ali.url;
        this.ewmloading = false; //加载状态结束
      } catch (error) {
        this.$tips(error.message);
      }
    },
    mealItemBtn(item, index) { //点击了哪个价格模板

      if (this.xzMeal !== index + 1) { //当点击的还是原来的元素的时候就禁止点击
        this.xzMeal = index + 1;
        this.getewm(item.id);
        this.zfbfukuanma = this.wxiszfbEwm.ali.url;
        this.copewith = item.price
      }
    },
    btnwxIszfb(index) {
      //微信支付还是支付宝支付
      this.wxiszfb = index;
    },
    async payisSuces() {
     
    try {
        let res = await inquirePaySuces({
            uid: this.isuid,
            out_trade_no:
                this.wxiszfb === 1
                    ? this.wxiszfbEwm.wx.out_trade_no
                    : this.wxiszfbEwm.ali.out_trade_no,
        });
   
        if (res.code === 200) {
            let data = res.data;
            data = assist.search(data, 'status', 2, true); // 过滤状态为2的数据
            data = assist.like(data, 'payment_time', assist.date()); // 过滤支付时间为当天的数据

            if (assist.count(data) >= 1) { // 支付成功
                // 处理支付成功的逻辑
                clearTimeout(this.interval); // 停止定时器
                this.interval = null; // 重置定时器标识
                this.times = 0; // 重置轮询次数
                //支付成功逻辑
               this.getbing(this.copewith,this.wxiszfb === 1? this.wxiszfbEwm.wx.out_trade_no: this.wxiszfbEwm.ali.out_trade_no)
               this.$emit('paysuccess')
            } else if (this.times >= this.maxTimes) { // 超过最大轮询次数
                // 处理支付超时的逻辑
                console.log("支付超时");
                clearTimeout(this.interval); // 停止定时器
                this.interval = null; // 重置定时器标识
                this.times = 0; // 重置轮询次数
                //支付超时逻辑
                this.$emit('payerror')
            } else {
                ++this.times; // 增加轮询次数
                this.interval = setTimeout(() => {
                    this.payisSuces(); // 继续轮询
                }, this.timeout);
            }
        } else {
            console.log('订单返回失败');
        }
    } catch (error) {
        console.error('查询支付状态时发生错误：', error);
    }
},
    dialogClose() {
      this.$emit("closevippop");
    },
    closevipBtn() {
      this.$emit("closevippop");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  border-radius: 20px !important;
}
::v-deep .el-dialog__headerbtn {
  font-size: 25px !important;
  font-weight: 800 !important;
  color: #000 !important;
}
::v-deep .el-dialog__body {
  padding: 0 0 15px 0 !important;
}

.vipbg {
  position: relative;
  width: 986px;
  height: 586px;
  background: url("@/assets/vip/vipbg.png") no-repeat;
  background-size: 100% 100%;
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog {
  box-shadow: 0;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}

.closevip {
  position: absolute;
  right: 3%;
  top: 3%;
  background: url("@/assets/vip/close.png") no-repeat;
  background-size: 100% 100%;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.pay {
  position: absolute;
  top: 37%;
  right: 3%;
  width: 299px;
  height: 327px;
  background-color: #fdfbff;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .payActive {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    .xzActive {
      background-color: #ffffff;
    }
    .payItem {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 0 10px;
      padding: 10px 15px;
      border-radius: 10px;
      span {
        white-space: nowrap;
      }
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
    }
  }
}
.amount {
  margin-top: 20px;
  font-size: 18px;
  color: #000000;
}

.payewm {
  width: 161px;
  height: 161px;
  border: 1px solid #000000;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.Setmeal {
  position: absolute;
  bottom: 7%;
  left: 4%;
  width: 60%;
  height: 158px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mealItemActive {
    background: linear-gradient(to bottom right, #ffbb57, #fa6685 50%, #a865d4);
    color: #fff !important;
  }

  .mealItem {
    cursor: pointer;
    width: 30%;
    height: 100%;
    border-radius: 18px;
    border: 1px solid #ff7242;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
    font-weight: 600;
    .title {
      font-size: 20px;
      margin-top: 10px;
    }
    .ling {
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    .yj {
      text-decoration: line-through;
      margin-top: 5px;
      display: flex;
      align-items: center;
  }
  }
}
</style>
