// messagePlugin.js
const MessagePlugin = {
  install(Vue) {
    // 添加一个实例方法来显示消息提示
    Vue.prototype.$tips = function(text) {
      const message = document.createElement('div');
      message.className = 'custom-message';
      message.textContent = text;

      // 添加样式
      message.style.backgroundColor = 'black';
      message.style.color = 'white';
      message.style.padding = '15px 20px';
      message.style.position = 'fixed';
      message.style.left = '50%';
      message.style.top = '50%';
      message.style.transform = 'translate(-50%, -50%)';
      message.style.borderRadius = '18px';
      message.style.opacity = '0';
      message.style.transition = 'opacity 0.5s ease-in-out';
      message.style.zIndex = '99999999'
      // 插入到 body 中
      document.body.appendChild(message);

      // 显示动画
      setTimeout(() => {
        message.style.opacity = '1';
      }, 100);

      // 隐藏动画及移除元素
      setTimeout(() => {
        message.style.opacity = '0';
        setTimeout(() => {
          message.remove();
        }, 500);
      }, 1000);
    };
  }
};

export default MessagePlugin;