import request from '@/utils/axios'
import assist from '@/utils/commonality'

export function verification(params) { //发送验证码
  return request({
    url: '/login/captcha',
    method: 'get',
    params
  })
}

export function login(params) { //通过验证码拿到uid
  return request({
    // url: 'login/phone',
    url: 'https://auth.server.sanwubeixin.cn/auth/login',
    method: 'get',
    params
  })
}

export function getUserUid() { //如果没有uid了 获取uid
  return request({
    url: '/launch/on_activate',
    method:'GET',
    headers:{
      param: JSON.stringify({"oaid": '', "imei": ''}),
      "os": assist.getOs(),
      "version": assist.getVersion(),
      "app_id": assist.getAppId(),
      "packet": assist.getPacket(),
      "channel_id": assist.getChannelId(),
      "other_id": assist.getOtherId(),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
      params: {
         mobile_os_version: ''
    },
  })
}



export function getUserInfo() { //通过uid拿到用户信息
  return request({
    url: 'https://auth.server.sanwubeixin.cn/auth/info',
    method:'GET',
      params: {
        jwt:assist.jwt.get() ,
        app_id: assist.getAppId(),
        uid:assist.getUid(),
        version: assist.getVersion(),
        packet: assist.getPacket(),
        channel_id: assist.getChannelId(),
        os: assist.getOs(),
        is_order: 1,
    },
  })
}

// 


// export function getUserInfo() { //通过uid拿到用户信息
//   return request({
//     url: '/user/info',
//     method:'get',
//     //   params: {
//     //     jwt: assist.jwt.get() || '',
//     //     app_id: assist.getAppId(),
//     //     uid: '85134041',
//     //     version: assist.getVersion(),
//     //     packet: assist.getPacket(),
//     //     channel_id: assist.getChannelId(),
//     //     os: assist.getOs(),
//     //     is_order: 1,
//     // },
//   })
// }


export function logoutApi() { //退出登陆
  return request({
    url: '/login/out',
  })
}

