import Vue from 'vue'
import Vuex from 'vuex'
import { getUserInfo, login,logoutApi } from '@/api/login'
import assist from '@/utils/commonality'
import { getspQuest} from '@/api/upload'
Vue.use(Vuex)
 
// 用来存储数据
const state = {
    uid:assist.getCache('uid') || '',
    userInfo:assist.getCache('userinfo') || {},
    loginVisible:false,//全局控制登录弹出层
    ispopvip:false ,//全局控制vip会员的弹出层
    datedetainment:false,//挽留弹窗的全局控制
    loading: false,//全局禁止点击的遮罩层
    sign:assist.getCache('sign') || '',//三方签名
    tabactive: assist.getCache('tabactive')|| '图片压缩',//页面tab切换
    hkactive:assist.getCache('hkactive') || 0,//顶部滑块的切换
    configforChannel:assist.getCache('configforChannel') ||  {},//客服一类的信息
}
// 响应组件中的事件
const actions = {
  async login(context, val) {//登录的逻辑
    try {
      // 登录并获取用户 UID
      const loginResponse = await login(val);
      let  uid = loginResponse.data.uid
      let jwt  = loginResponse.data.jwt
      if (uid) {
           // 将 UID 存入 Vuex 状态
      context.commit('loginMutations', uid);
      assist.setCache('uid',uid) //存入uid
      assist.jwt.set(jwt) //存入jwt
      context.dispatch('getuserinfo') 
      return {success:true,data:loginResponse.data}
      }else {
        return {success:false,message:'登录失败，未获取到用户UID'}
      }
    } catch (error) {
      console.error('登录错误', error);
      return {success:false,error:error.message||'登录过程中发生错误'}
    }
  },
 async logout(context,val) {//退出的
     try {
         await logoutApi()
         assist.clearCache('uid')
         assist.clearCache('userinfo')
         assist.jwt.clear();
         context.commit('updatelogout')
         assist.clearCache('sign')
         return {success:true}
     } catch (error) {
      console.error('退出错误', error);
      return {success:false,error:error.message||'请稍后重试'}
     }
    },
   async getuserinfo(context,val) {
        const userInfoResponse = await getUserInfo();
        context.commit('getUserInfo', userInfoResponse.data);
        assist.jwt.set(userInfoResponse.data.jwt);
        assist.setCache('userinfo', userInfoResponse.data, (new Date()).getTime() + 2700000);
        context.dispatch('getSign',userInfoResponse)

    },
  async  getSign(context,val) {//获取签名
      let res =  await getspQuest('/api/cost/bySignInfo','post','17',{
        token:val.data.jwt
      })
      assist.setCache('sign', res.data.sign);
      context.commit('updateSign',res.data.sign)
    },
    startLoading({ commit },val) { //开启禁止点击
      commit('setLoading', val);
    },

  async  getconfigforChannel(context,val) {
            let res =    await getspQuest(
              '/api/basic/getCfgForChannel',
              'get',
              '1'
            )
            assist.setCache('configforChannel', res.data);
            context.commit('updateconfigforChannel', res.data)
    }

}
// 操作数据
const mutations = {
  loginMutations(state,val) { //修改uid的
    state.uid = val
  },
  getUserInfo(state,val) {//修改用户个人信息的
      state.userInfo = val
  },
  updateloginVisible(state,val) {//修改控制登录弹出层的
      state.loginVisible = val
  },
  updatispopvip(state,val) {//修改控制vip弹出层的
    state.ispopvip = val
  },
  updatedetainment(state,val) {//修改挽留弹窗的弹出层
      state.datedetainment = val
  },
  updatelogout(state,val) {//退出
      state.userInfo = {}
      state.sign = ''
  },
  setLoading(state, loading) { //禁止点击的遮罩层
    state.loading = loading;
  },
  updateSign(state,val) {//修改三方签名
    state.sign = val
  },
  updatetabactive(state,val) { //修改tab切换的值
    state.tabactive = val 
    assist.setCache('tabactive', val);
  },
  updateconfigforChannel(state,val) {//修改客服一类的信息
    state.configforChannel = val
  },
  updatehkactive(state,val) {//修改顶部滑块的值
    state.hkactive = val
    assist.setCache('hkactive', val);
  }
}
// 用来将state数据进行加工
const getters = {
    isuid:state => state.uid,
    isloginVisible:state => state.loginVisible,
    isispopvip : state => state.ispopvip,
    isuserInfo :state => state.userInfo,
    isdatedetainment:state => state.datedetainment,
    isstorpclick: state => state.loading,
    issign :state => state.sign,
    istabactive:state => state.tabactive,
    isconfigforChannel:state => state.configforChannel,
    ishkactive:state => state.hkactive
}
// 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})
 