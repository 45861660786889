import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui';
import assist from '@/utils/commonality';
import Vue from 'vue'
// 全局设置 transformResponse

const service = axios.create({
  baseURL: 'https://lm.center.sanwubeixin.cn',      
  timeout: 100000 
})

// request interceptor
service.interceptors.request.use(
 async config => {
    config.headers.uid = await assist.getUid(true)
    config.headers.app_id = assist.getAppId()
    config.headers.packet= assist.getPacket()
    config.headers.channel_id=assist.getChannelId()
    config.headers.platform_id=assist.getPlatform()
    config.headers.version=assist.getVersion()
    config.headers.os='1' //写死
    config.headers['Content-Type'] = 'application/json'
    config.headers['Access-Control-Allow-Origin'] = '*'

    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    
      if (typeof response.data === 'string') { //需要解密
            let jmsuccess = assist.decrypt(response.data)

            if (jmsuccess.code === 200) {
              return jmsuccess
            }else {
              Promise.reject(new Error(res.message || '请稍后重试'));
              return jmsuccess
            }
      }else { //不需要解密 
        if(res.code!==200) {
          if (res.message === '鉴权失败') {
            return
          }
          // Message.error(res.message || '请稍后重试');
          Vue.prototype.$tips(res.message || '请稍后重试')
          return Promise.reject(new Error(res.message || '请稍后重试'));
        }else {
          return res
        }
      }
   
  },
  error => {
    console.log('err' + error); // 用于调试
    
    // 显示错误消息
    // Message.error(error.message || '请稍后重试');
    Vue.prototype.$tips(error.message || '请稍后重试')
    // 拒绝 promise 以便外部捕获错误
    return Promise.reject(error);
  }
)

export default service
