<template>
  <div class="compress">
    <div class="compressLeft">
        <div class="leftItem" 
        v-for="(item,index) in leftArr"
        :key="index"
        >
              <div class="letItemTitle">{{item.text}}</div>
              <div :class="['leftItemActive', $store.getters.istabactive ===it.ztext?'xz':'',]" 
              @click="compressItemBtn(it.ztext,it)"
              v-for="(it,j) in item.leftArrChilder"
              :key="j"
              >
                    <img :src="it.zimg" alt="">
                   <span>{{it.ztext}}</span>
              </div>
        </div>

    </div>
    <div class="compressRight">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      leftArr: [
        {
          id: 1,
          text: "图片压缩",
          leftArrChilder: [
            { zid: 1, zimg: require('@/assets/compressicon/imgys.png') , ztext: "图片压缩",pushUrl:'/' },
            // { zid: 2, zimg: require('@/assets/compressicon/gifys.png')   , ztext: "GIF压缩",pushUrl:'/gif' },
            { zid: 3, zimg: require('@/assets/compressicon/zjzys.png')   , ztext: "证件照压缩" ,pushUrl:'/zjz' },
            { zid: 4, zimg: require('@/assets/compressicon/zhiding.png') , ztext: "压缩指定大小" ,pushUrl:'/zd' },
          ],
        },
        {
          id: 2,
          text: "图片改尺寸",
          leftArrChilder: [
            { zid: 1, zimg: require('@/assets/compressicon/imgchicun.png') , ztext: "图片改尺寸",pushUrl:'/cc' },
            { zid: 2, zimg: require('@/assets/compressicon/dpi.png') , ztext: "修改DPI" ,pushUrl:'/dpi'},
          ],
        },
        {
          id: 3,
          text: "视频压缩",
          leftArrChilder: [
            { zid: 1, zimg: require('@/assets/compressicon/shipin.png') , ztext: "视频压缩",pushUrl:'/sp' },
          ],
        },
      ],
      xzActive:'图片压缩'
    };
  },
  computed: {},
  watch: {
  
  },
  created() {},
  mounted() {
  },
  methods: {
       compressItemBtn(ztext,it) {
        this.$store.commit('updatetabactive',ztext)
       this.xzActive = ztext
      //  this.$router.push({
      //   path:`${it.pushUrl}?v=${ztext}`
      //  })
        this.$router.push(it.pushUrl)
    }
  },
};
</script>

<style scoped lang="scss">
.compress {
  width: 100%;
  height: 762px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .compressLeft {
    width: 18%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 18px;
    padding-top: 15px;
    box-sizing: border-box;
    .leftItem {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      margin-bottom: 25px;
      .letItemTitle {
        color: rgba(133, 133, 133,1);
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        padding-left: 20px;
      }
      .xz {
          background-color: #f6f6f6;
          span {
          color: #000 !important;

          }
      }
      .leftItemActive {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 38px;
          height: 38px;
          margin-right: 15px;
        }
        span {
          // font-size: larger;
          color: #969696;
        }

      }
    }
  }
  .compressRight {
    width: 78%;
    height: 100%;
    overflow: hidden;
    border-radius: 18px;
  }
}
</style>
