import JSZip from 'jszip';

/**
 * 下载并打包文件到ZIP
 * @param {Array} urls - 需要打包的文件链接数组
 * @param {String} zipName - ZIP文件名
 */
export const downloadAndZipFiles = async (urls, zipName = 'files.zip') => {
  try {
    const zip = new JSZip();

    const fetchAndAddToZip = async (url, index) => {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Failed to fetch ${url}`);
        
        const arrayBuffer = await response.arrayBuffer();
        const contentType = response.headers.get('Content-Type');
        const filename = `file${index + 1}${getFileExtension(contentType, url)}`;

        if (contentType === 'application/zip') {
          // 如果是 .zip 文件，解压缩并添加其内容
          const zipContent = await JSZip.loadAsync(arrayBuffer);
          zipContent.forEach((relativePath, file) => {
            zip.file(`file${index + 1}/` + relativePath, file.async('arrayBuffer'));
          });
        } else {
          // 如果不是 .zip 文件，直接添加
          zip.file(filename, arrayBuffer);
        }
      } catch (err) {
        console.error('Fetch or unzip error:', err);
      }
    };

    // 使用 Promise.all 以并行方式处理文件
    await Promise.all(urls.map(fetchAndAddToZip));

    // 生成 ZIP 文件并触发下载
    const content = await zip.generateAsync({ type: 'blob' });
    const blobUrl = URL.createObjectURL(content);
    
    // 创建一个隐藏的 <a> 标签进行下载
    const aElement = document.createElement('a');
    aElement.href = blobUrl;
    aElement.download = zipName;
    aElement.style.display = 'none';
    document.body.appendChild(aElement);
    aElement.click();

    // 清理创建的 URL 对象和 <a> 标签
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(aElement);
  } catch (err) {
    console.error('ZIP creation error:', err);
  }
};

/**
 * 根据 Content-Type 或 URL 获取文件扩展名
 * @param {String} contentType - 文件的 Content-Type
 * @param {String} url - 文件的 URL
 * @return {String} 文件的扩展名
 */
const getFileExtension = (contentType, url) => {
  const extensionFromMimeType = {
    'application/zip': '.zip',
    'application/pdf': '.pdf',
    'image/jpeg': '.jpg',
    'image/png': '.png',
    // 添加更多的 MIME 类型和扩展名映射
  };

  // 如果 Content-Type 匹配，返回对应扩展名
  if (contentType && extensionFromMimeType[contentType]) {
    return extensionFromMimeType[contentType];
  }

  // 否则，从 URL 中提取扩展名
  const extensionFromUrl = url.split('.').pop().split('?')[0];
  return extensionFromUrl ? `.${extensionFromUrl}` : '';
};








  export const downloadFile =  async(url) => {
  try {
    // 使用 fetch 获取文件内容
    const response = await fetch(url, {
      mode: 'cors', // 确保跨域请求模式
    });
    
    if (!response.ok) {
      throw new Error(`下载失败: ${response.statusText}`);
    }

    // 获取文件的 Blob 对象
    const blob = await response.blob();

    // 获取文件名
    const filename = extractFileName(url);

    // 创建临时的 <a> 元素
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // 附加到 DOM 并触发点击事件
    document.body.appendChild(link);
    link.click();

    // 清理
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('下载错误:', error);
  }
}

/**
 * 从 URL 中提取文件名
 * @param {string} url - 文件的 URL
 * @returns {string} 文件名
 */
 const  extractFileName  =(url) =>{
  const urlSegments = url.split('/');
  return urlSegments[urlSegments.length - 1] || 'downloaded-file';
}