import { render, staticRenderFns } from "./imgcompress.vue?vue&type=template&id=446d0513&scoped=true&"
import script from "./imgcompress.vue?vue&type=script&lang=js&"
export * from "./imgcompress.vue?vue&type=script&lang=js&"
import style0 from "./imgcompress.vue?vue&type=style&index=0&id=446d0513&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446d0513",
  null
  
)

export default component.exports