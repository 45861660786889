import Router from 'vue-router'
import Vue from 'vue'
Vue.use(Router)


// 
const originalPush = Router.prototype.push 
Router.prototype.push = function push(location) {
  return originalPush.call(this,location).catch(err => err)
}

import Home from '@/view/home'
import compress from '@/view/type/compress.vue'
import imgcompress from '@/view/compresstype/imgcompress.vue'
// import shouji from '@/view/shouji'
export default new Router({
  // mode: "history", 
  routes :[
  { 
    path:'/',
    component:Home,
    children:[
      {
        path:'/',
        component:compress,
        children:[
          {
            path:'/',
            component:imgcompress
          },
          {
            path:'/gif',
            component:()=> import('@/view/compresstype/gifcompress.vue')
          },
          {
            path:'/zjz',
            component:()=> import('@/view/compresstype/zjzcompress.vue')
          },
          {
            path:'/zd',
            component:()=> import('@/view/compresstype/zd.vue')
          },
          {
            path:'/cc',
            component:()=> import('@/view/compresstype/cc.vue')
          },
          {
            path:'/dpi',
            component:()=> import('@/view/compresstype/dpi.vue')
          },
          {
            path:'/sp',
            component:()=> import('@/view/compresstype/sp.vue')
          },
          {
            path:'/about',
            components:{qiguai:()=> import('@/components/gywm.vue')}
          },
        ]
      },
      {
        path:'/convert',
        component:() => import('@/view/type/convert.vue'),
        children:[
          {
            path:'/convert',
            component:()=> import('@/view/converttype/imgzh.vue')
          },
          {
            path:'/convert/wordimg',
            component:()=> import('@/view/converttype/wordimg.vue')
          },
          {
            path:'/convert/excelimg',
            component:()=> import('@/view/converttype/excelimg.vue')
          },
          {
            path:'/convert/heifimg',
            component:()=> import('@/view/converttype/heifimg.vue')
          },
          {
            path:'/convert/tiffimg',
            component:()=> import('@/view/converttype/tiff.vue')
          },
          {
            path:'/convert/pdfimg',
            component:()=> import('@/view/converttype/pdfimg.vue')
          },
          {
            path:'/convert/imgicon',
            component:()=> import('@/view/converttype/imgicon.vue')
          },
          {
            path:'/convert/pptimg',
            component:()=> import('@/view/converttype/pptimg.vue')
          },
        ]
      },
      {
        path:'/pdfcompress',
        component:() => import('@/view/type/pdfcompress.vue'),
        children:[
          {
            path:'/pdfcompress',
            component:()=> import('@/view/pdftype/pdfys.vue')
          },
          {
            path:'/pdfcompress/pdfword',
            component:()=> import('@/view/pdftype/pdfword.vue')
          },
          {
            path:'/pdfcompress/pdfppt',
            component:()=> import('@/view/pdftype/pdfppt.vue')
          },
          {
            path:'/pdfcompress/pdfexcel',
            component:()=> import('@/view/pdftype/pdfexcel.vue')
          },
          {
            path:'/pdfcompress/pdfimg',
            component:()=> import('@/view/pdftype/pdfimg.vue')
          },
          {
            path:'/pdfcompress/wordpdf',
            component:()=> import('@/view/pdftype/wordpdf.vue')
          },
          {
            path:'/pdfcompress/pptpdf',
            component:()=> import('@/view/pdftype/pptpdf.vue')
          },
          {
            path:'/pdfcompress/excelpdf',
            component:()=> import('@/view/pdftype/excelpdf.vue')
          },
          {
            path:'/pdfcompress/imgpdf',
            component:()=> import('@/view/pdftype/imgpdf.vue')
          },
        ]
      },
      {
        path:'/pdfconvert',
        component:() => import('@/view/type/pdfconvert.vue')
      },
    ]
  },
  {
    path:'/index',
    component:() => import('@/view/index.vue'),

  },
  {
    path:'/my',
    component:() => import('@/view/my.vue')
  }

  ]


})