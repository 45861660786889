<template>
  <el-dialog
    @close="dialogClose"
    :visible="isdatedetainment"
    :close-on-click-modal="zzcIsShow"
    width="30%"
    top="5vh"
  >
    <div class="vipbg">
      <div class="titleBt">
        <img src="@/assets/vip/jjtext.png" alt="" />
      </div>

      <div class="jj">
        <div class="jjimgContent">
          <img src="@/assets/vip/jj.png" alt="" />
          <div class="rc">
            <div>￥{{favorable.show_price}}</div>
            <div>￥{{favorable.show_price}}</div>
          </div>
          <div class="zj">
                ￥{{favorable.xr_price}}
          </div>
        </div>
        <div class="finally">活动最终价 ￥{{favorable.xr_price}}</div>

        <div class="jisuan">
          <div class="jisuanItem">
            <div class="itemTop">
              <span>￥</span><span class="rijg">{{favorable.show_price}}</span>
            </div>
            <div class="itemBotm">日常价格</div>
          </div>
          <!-- <div class="jian"></div> -->
          <!-- <div class="jisuanItem">
            <div class="itemTop">
              <span>￥</span><span class="rijg">100</span>
            </div>
            <div class="itemBotm">新人福利</div>
          </div> -->
          <div class="jian"></div>
          <div class="jisuanItem">
            <div class="itemTop">
              <span>￥</span><span class="rijg">{{favorable.xr_price}}</span>
            </div>
            <div class="itemBotm">优惠卷</div>
          </div>
          <div class="dengyu">
            <div></div>
            <div></div>
          </div>
          <div class="jisuanItem">
            <div class="itemTop">
              <span>￥</span><span class="rijg">{{favorable.price}}</span>
            </div>
            <div class="itemBotm">活动最终价</div>
          </div>
        </div>
      </div>

 

      <!-- 点击了立即抢购 -->
      <div class="twoEwm" v-show="btnljqg">
        <div class="wxewm">
          <div class="wxewmrq">
            <img
              style="width: 95%; height: 95%"
              st
              :src="wxzhifuerweima"
              alt=""
            />
          </div>
          <img class="zficon" src="@/assets/vip/wx.png" alt="" />
        </div>
        <div class="zfbewm">
          <div class="zfbwmrq">
            <iframe
              width="78%"
              height="82%"
              :src="zfbfukuanma"
              frameborder="0"
            ></iframe>
          </div>
          <img class="zficon" src="@/assets/vip/zfb.png" alt="" />
        </div>
      </div>

      <div >
        <div class="ljqg" @click="ljqgBtn" v-show="!btnljqg">立即抢购</div>
        <div class="crlk" @click="crlkBtn">残忍离开</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { priceTemplate, getPriceUrl,inquirePaySuces ,bingApi} from "@/api/pay";
import QRCode from "qrcode"; //生成二维码的库
import { mapGetters, mapMutations } from "vuex";
import assist from '@/utils/commonality'

export default {
  mixins: [],
  components: {},
  props: {

  },
  data() {
    return {
      btnljqg: false, //点击了立即抢购
      zzcIsShow: false, //遮罩层是否可关闭
      wxiszfbEwm: {
        wx: { url: "", out_trade_no: "" },
        ali: { url: "", out_trade_no: "" },
      },
      wxzhifuerweima: "", //微信支付的二维码
      zfbfukuanma: "", //支付宝支付的二维码
      ewmloading: false, //二维码加载状态
       timeout: 2e3,//轮询时间
      interval:null,
      times:0,//
      maxTimes:200,//
        templateData: { //价格模板
        app_id: assist.getAppId(),
        channel_id: assist.getChannelId(),
        packet: assist.getPacket(),
        other_id: assist.getOtherId(),
      },
      favorable:{

      }
    };
  },
  computed: {
    ...mapGetters(["isispopvip", "isuid", "isuserInfo",'isdatedetainment']),
  },
  watch: {
   async isdatedetainment(newl) {
      if (newl) {
         await this.gettemplateList()
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    ...mapMutations(['updatedetainment']),
     async getbing(price, out_trade_no, callback) { //价格 订单号 回调方法  需要支付价格跟订单号
      let res = '';
    if (assist.getOtherId()=='bing') {
        let res = await bingApi({
            out_trade_no:out_trade_no
        })
        if (res.data.status === 1) {
              window.uetq = window.uetq || [];
        window.uetq.push('event', 'purchase', {"revenue_value": price, "currency": "CNY"});
        }
    }
       
        // 成功以后自定义逻辑
        //需要判断  
        /**
         * 判断当前的环境是不是必应  判断回传成功没
         */
        // callback && callback();
        return res;
    },
   async gettemplateList() {
   
       let res =    await  priceTemplate(this.templateData)
            this.favorable = res.data.list[res.data.list.length-1]
            this.getewm(this.favorable.id);
     
    },
    dialogClose() {
      this.$emit("closevippopwl");
      this.btnljqg = false;  
      this.zzcIsShow = false;
    },

    ljqgBtn() {
      //立即抢购btn
      this.btnljqg = true; //显示二维码
      this.zzcIsShow = true; //遮罩层可以关闭

    if (this.interval !== null) clearTimeout(this.interval);
          this.interval  =  setTimeout(() => {
                this.payisSuces();
         },this.timeout)

    },
    crlkBtn() {
      clearTimeout(this.interval)
      //残忍离开btn
      this.$emit("closevippopwl");
    },

    async getewm(shopid) {
      this.ewmloading = true; //开启loading加载
      try {
        let res = await getPriceUrl({
          appid: assist.getAppId(),
          channel_id: assist.getChannelId(),
          packet: assist.getPacket(), 
          other_id: assist.getOtherId(),
          uid: this.isuid,
          custom: JSON.stringify({
                       shop_name: '图片视频压缩',
                       shop_id: shopid,
             }),
          shop_id: shopid,
          redirect_url: window.location.href,
        });
     
        this.wxiszfbEwm = res.data;
        // 生成二维码图片
          let url = await new Promise((resolve, reject) => {
            QRCode.toDataURL(res.data.wx.url, (err, url) => {
              if (err) {
                reject(err);
              } else {
                resolve(url);
              }
            });
          });
        // 设置图片的 src
        this.wxzhifuerweima = url;
        this.zfbfukuanma = res.data.ali.url;
        this.ewmloading = false; //加载状态结束
      } catch (error) {
        this.$tips(error.message);
      }
    },
      async payisSuces() {
       
    try {

       let [res, res1] = await Promise.all([
      inquirePaySuces({
        uid: this.isuid,
        out_trade_no: this.wxiszfbEwm.wx.out_trade_no
      }),
      inquirePaySuces({
        uid: this.isuid,
        out_trade_no: this.wxiszfbEwm.ali.out_trade_no
      })
    ]);
      

        if (res.code === 200 ||res1.code === 200) {
            let data = res.data;
            let data1 = res1.data
            data = assist.search(data, 'status', 2, true); // 过滤状态为2的数据
            data = assist.like(data, 'payment_time', assist.date()); // 过滤支付时间为当天的数据
            data1 = assist.search(data1, 'status', 2, true); // 过滤状态为2的数据
            data1 = assist.like(data1, 'payment_time', assist.date()); // 过滤支付时间为当天的数据
            if (assist.count(data) >= 1 || assist.count(data1) >= 1) { // 支付成功
                // 处理支付成功的逻辑
                console.log("支付成功");
                clearTimeout(this.interval); // 停止定时器
                this.interval = null; // 重置定时器标识
                this.times = 0; // 重置轮询次数
                //支付成功逻辑
                this.$tips("支付成功");
                this.getbing(this.favorable.price,data.out_trade_no===''?data1.out_trade_no:data.out_trade_no)
                this.$emit('paysuccess')
            } else if (this.times >= this.maxTimes) { // 超过最大轮询次数
                // 处理支付超时的逻辑
                clearTimeout(this.interval); // 停止定时器
                this.interval = null; // 重置定时器标识
                this.times = 0; // 重置轮询次数
                this.updatedetainment(false)
                // 在这里调用  把订单号跟价格传递给  home的bing  作业内容
                




                //支付超时逻辑
                this.$emit('payerror')
            } else {
                ++this.times; // 增加轮询次数
                this.interval = setTimeout(() => {
                    this.payisSuces(); // 继续轮询
                }, this.timeout);
            }
        } else {
            console.log('订单返回失败');
        }
    } catch (error) {
        console.error('查询支付状态时发生错误：', error);
    }
},

  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  border-radius: 20px !important;
}
::v-deep .el-dialog__headerbtn {
  font-size: 25px !important;
  font-weight: 800 !important;
  color: #000 !important;
}
::v-deep .el-dialog__body {
  padding: 0 0 15px 0 !important;
}

.vipbg {
  padding: 20px 0;
  position: relative;
  width: 502px;
  background: url("@/assets/vip/wlbg.png") no-repeat;
  background-size: 100% 100%;
  .titleBt {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    img {
      width: 400px;
      height: 57px;
    }
  }

  .jj {
    position: relative;
    display: flex;
    justify-content: center;
    .jjimgContent {
      width: 100%;
      position: relative;
      .zj {
       position: absolute;
    top: 41%;
    left: 43%;
    font-size: 21px;
    font-weight: 600;
    color: #ffffff;
      }
      .rc {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 20%;
        left: 7%;
        width: 85%;
        font-weight: 600;
        color: #ff6640;
      }
    }
    .finally {
      position: absolute;
      bottom: 31%;
      font-size: 18px;
      font-weight: 600;
      color: #ff7d52;
    }
    img {
      width: 100%;
      height: 365px;
    }

    .jisuan {
      position: absolute;
      bottom: 10%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 10px;
      box-sizing: border-box;
      width: 69%;
      .jisuanItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        .itemTop {
          font-weight: 600;
          font-size: 18px;
          .rijg {
            font-size: 25px;
          }
        }
        .itemBotm {
          font-size: 12px;
        }
      }

      .jian {
        width: 10px;
        height: 2px;
        background-color: #fff;
        margin: 0 5px;
      }
      .dengyu {
        div {
          width: 13px;
          height: 2px;
          background-color: #fff;
          margin: 5px 0;
        }
      }
    }
  }

  .ljqg {
    cursor: pointer;
    width: 216px;
    height: 54px;
    border-radius: 16px;
    text-align: center;
    line-height: 54px;
    margin: 0 auto;
    margin-top: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(to bottom right, #ffbb57, #fa6685 50%, #a865d4);
  }

  .crlk {
    cursor: pointer;
    text-align: center;
    color: #ff6640;
    font-weight: 600;
    // margin-top: 56px;
    margin-top: 14px;
  }

  .twoEwm {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .wxewmrq,
    .zfbwmrq {
      width: 156px;
      height: 156px;
      border: 1px solid #000;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .zficon {
      width: 17px;
      height: 17px;
      margin-top: 15px;
    }
  }
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog {
  box-shadow: 0;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}
</style>
