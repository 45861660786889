// globalMixin.js
import store from '@/store'; // 引入 Vuex store
import {getspQuest } from '@/api/upload'
export default {
  methods: {
  
    async getNewsign () {
     let newsign =   await getspQuest('/api/cost/bySignInfo','post','17',{
        token:this.$store.getters.isuserInfo.jwt
      })

      return  newsign.data.sign
    },
    uploadBeforeBtn() { //登录之前上传文件禁止子元素冒泡
      if (this.$store.getters.isuserInfo.isSign) {
         //如果登录了就判断有没有开通会员
         if (!this.$store.state.userInfo.isVip) {
           this.$store.commit("updatispopvip", true);
         }else {
             this.$refs.upload.$children[0].$refs.input.click();
         }
       } else {
         //先判断有没有登录  没有登录让登录
         //如果登录了判断有没有会员
         this.$tips("请先登录后再上传文件！");
         this.$store.commit("updateloginVisible", true);
       }
     },
  }
};