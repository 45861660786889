<template>
  <div class="bottomw">
   <div class="bottomz">
         <div class="bottomL">
      <div class="lTitle">关于我们</div>
      <div class="lP">
        一家致力于软件研发的互联网科技公司，继承了研发与市场团队多年技术积累和市场经验，旗下拥有强大的AI
        自然语言处理、图像处理和机器学习等技术的结合，实现了自动化、个性化和高效率的PPT生成过程，满足您的任意创作需求。
      </div>
    </div>

        <div class="bottomR">
      <div class="rTitle" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'">联系方式</div>
      <div class="rItem" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'&&  isconfigforChannel?.ability?.used.func.online_service_info !=='' &&  isconfigforChannel?.ability?.used.func.online_service_info !=='0'">
        <!-- <img src="@/assets/bottom/dh.png" alt=""> -->
        <span>在线客服：<a :href="isconfigforChannel?.ability?.used.func.online_service_info" style="color: #999999;font-size:14px">点击跳转</a></span>
        <span></span>
      </div>
      <div class="rItem" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'&&  isconfigforChannel?.ability?.used.func.customer_serv !==''&&  isconfigforChannel?.ability?.used.func.customer_serv !=='0'">
        <span>电话：{{isconfigforChannel?.ability?.used.func.customer_serv}}</span>
        <span></span>
      </div>
      <div class="rItem" v-if="isconfigforChannel?.ability?.used.func.corporation && isconfigforChannel?.ability?.used.comm.is_active =='1'&&isconfigforChannel?.ability?.used.func.corporation !== ''&&isconfigforChannel?.ability?.used.func.corporation !== '0'">
        <span>公司：{{isconfigforChannel?.ability?.used.func.corporation}}</span>
        <span></span>
      </div>
       <div class="rItem" v-if="isconfigforChannel?.ability?.used.func.location &&isconfigforChannel?.ability?.used.comm.is_active =='1'&&isconfigforChannel?.ability?.used.func.location !== ''&&isconfigforChannel?.ability?.used.func.location !== '0'">
        <span>地址：{{isconfigforChannel?.ability?.used.func.location}}</span>
        <span></span>
      </div>
    </div>
   </div>

   <div class="hx"></div>

   <div class="db">
      <div class="dbL">
        <div class="dbLItem">
          <img src="@/assets/bottom/one.png" alt="">
          <span>甄选精品</span>
        </div>
         <div class="dbLItem">
          <img src="@/assets/bottom/two.png" alt="">
          <span>高效办公</span>
        </div>
         <div class="dbLItem">
          <img src="@/assets/bottom/three.png" alt="">
          <span>质优价廉</span>
        </div>
         <div class="dbLItem">
          <img src="@/assets/bottom/four.png" alt="">
          <span>极速处理</span>
        </div>
      </div>
      <div class="dbR" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'&&isconfigforChannel?.ability?.used?.func.bottom_content !==''&&isconfigforChannel?.ability?.used?.func.bottom_content !=='0'">
        Copyright © 2024 {{isconfigforChannel?.ability?.used.func.corporation}}   All Rights Reserved. {{ isconfigforChannel?.ability?.used?.func.bottom_content}}
      </div>
   </div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex'
export default {
  mixins: [],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
      ...mapGetters(['isconfigforChannel'])
  },
  watch: {},
  created() {
 
  },
  mounted() {
       this.getCfgForChannel()

  },
  methods: {
  async  getCfgForChannel() {
          this.$store.dispatch('getconfigforChannel')
    }
  },
};
</script>

<style scoped lang="scss">
.bottomw {
  width: 80%;
  margin: 0 auto;
  
  padding-top: 30px;
 

  .bottomz {
display: flex;
  justify-content: space-between;
    color: #fff; 
    margin-bottom: 15px;
  }

  .bottomL {
    padding-bottom: 20px;
    .lTitle {
      margin-bottom: 15px;
    }
    .lP {
      width: 382px;
      color: #999999;
      font-size: 14px;
    }
  }

  .bottomR {
    width: 250px;
    .rTitle {
      margin-bottom: 20px;
    }
    .rItem {
      display: flex;
      align-items: center;
      margin: 15px 0;
      img {
        width: 13px;
        height: 13px;
        display: block;
        margin-right: 10px;
      }
      span {
        font-size: 14px;
        color: #999999;
      }
    }
  }
}

.hx {
  width: 100%;
  height: 1px;
  background-color: #404040;
}

.db {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
  .dbL {
    display: flex;
    align-items: center;
  }
  .dbLItem {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #999999;
    img {
      width: 24px;
      height: 28px;
      margin-right: 10px;
    }
  }
  .dbR {
    font-size: 14px;
    color: #999999;
  }
}
</style>
