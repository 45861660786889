<template>
  <el-dialog
    @close="dialogClose"
    :visible="grzxshow"
    :close-on-click-modal="false"
    width="30%"
  >
    <div class="grzx">
      <!-- 头像部分 -->
      <div class="titleavatar">
        <div class="avatarrq">
           <el-avatar :size="70" :src="isuserInfo?.avatar" >
                    <img src="@/assets/morentx.png"/>
             </el-avatar>
          <img v-if="isuserInfo.vip_time!==0" class="viphg" src="@/assets/grzx/vipwk.png" alt="" />
        </div>
        <img @click="closegrzx" class="closeimg" src="@/assets/grzx/grzxclose.png" alt="" />
      </div>

      <h3 style="color: #000000; margin: 20px 0">基本信息</h3>

      <div class="xxItem">
        <span class="gd"><span>昵</span><span>称</span></span
        >：<span class="yhxx">{{isuserInfo?.username}}</span>
      </div>
      <div class="xxItem">
        <span class="gd"><span>账</span><span>户</span><span>ID</span></span
        >：<span class="yhxx">{{isuserInfo?.uid}}</span>
      </div>
      <div class="xxItem">
        <span class="gd"
          ><span>登</span><span>陆</span><span>方</span><span>式</span></span
        >：<span class="yhxx">{{isuserInfo?.wx_openid===''?'手机号登录':'微信登录'}}</span>
      </div>
      <div class="xxItem">
        <span class="gd"
          ><span>会</span><span>员</span><span>时</span><span>间</span></span
        >：<span class="yhxx">{{computeVip}}</span>
      </div>

      <!-- 底部按钮 -->
          <div class="ljBtn" @click="vipBtn">
            {{isuserInfo.vip_time===0?'立即开通':'立即续费'}}
          </div>

    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [],
  components: {},
  props: {
    grzxshow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sucesUserInfo:{}
    };
  },
  computed: {
    ...mapGetters(['isuserInfo','isgrzs']),
    computeVip() {
       if (this.isuserInfo.vip_time === 4092595200) {
        return "永久会员";
      }
      if(this.isuserInfo.vip_time ===0) {
        return '非会员'
      }
      // 获取并检查时间戳
      const vipTimestamp = Number(this.isuserInfo.vip_time); // 确保是数字类型

      // 转换时间戳为毫秒
      const date = new Date(vipTimestamp * 1000); // 乘以 1000 以转换为毫秒

      // 获取年份
      const year = date.getFullYear();

      // 获取月份，注意月份从 0 开始，所以需要加 1
      const month = String(date.getMonth() + 1).padStart(2, "0");

      // 获取日期
      const day = String(date.getDate()).padStart(2, "0");

      // 返回格式化后的日期
      return `${year}.${month}.${day}`;
    }
  },
  watch: {},
  created() {},
  mounted() {
  
    
  },
  methods: {
    dialogClose() {
      this.$emit('closegrzx')
    },
    closegrzx() {
      this.$emit('closegrzx')
    },
    vipBtn() {
     this.$emit('tapBtn')
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  border-radius: 20px !important;
}
::v-deep .el-dialog__headerbtn {
  font-size: 25px !important;
  font-weight: 800 !important;
  color: #000 !important;
}
::v-deep .el-dialog__body {
  padding: 0 0 15px 0 !important;
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog {
  box-shadow: 0;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}

.grzx {
  background: url("@/assets/grzx/grzxbg.png") no-repeat;
  background-size: 100% 100%;
  width: 400px;
  height: 500px;
  padding: 40px 50px;
  box-sizing: border-box;
  .avatarrq {
    position: relative;
    .yhavatar {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
    .viphg {
      width: 91px;
      position: absolute;
      top: -33%;
      left: -2%;
    }
  }
  .titleavatar {
    display: flex;
    justify-content: space-between;

    .closeimg {
      cursor: pointer;
      width: 45px;
      height: 45px;
    }
  }
}

.xxItem {
  color: #000;
  margin: 30px 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  .gd {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .yhxx {
    margin-left: 10px;
  }
}

.ljBtn {
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  margin: 0 auto;
  width: 250px;
  height: 55px;
  background: linear-gradient( 135deg, #FF6640 0%, #FF7A41 100%);
  border-radius: 16px;
}
</style>
