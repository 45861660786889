<template>
  <div class="about"></div>
</template>

<script>
export default {
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="scss">
.about {
  height: 100%;
  background: url('@/assets/gywm.jpg') no-repeat;
  background-size: 100% 100%;
  background-position-y: 40%;
  width: 100%;
  height: 700px;
}
</style>
